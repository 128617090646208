import axios from "axios";

export const API = axios.create({
  baseURL: "https://api.myloop.id",
});

// intercept response code, if response code is not 200, then throw error
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status > 400 && error.response.status < 500) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }

    throw new Error(error);
  }
);
