import { useRef, useState, useEffect } from "react";
import "normalize.css";
import QrScanner from "qr-scanner";
import "../App.css";
import { useAuth } from "../hooks/useAuth";
import { API } from "../api";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

export default function Home() {
  const qrRef = useRef(null);
  const qrScanner = useRef(null);
  const [scanned, setScanned] = useState(false);

  const [url, setUrl] = useState("");

  const navigate = useNavigate();
  useAuth();

  //   https://api.myloop.id/myloop/core-myloop/<shortCodeHere>/

  useEffect(() => {
    if (qrRef.current)
      qrScanner.current = new QrScanner(
        qrRef.current,
        async (result) => {
          if (result) {
            setScanned(true);
            const url = new URL(result.data);
            if (url.origin.includes("myloop")) {
              const shortCode = url.pathname.replace("/s/", "");

              if (shortCode) {
                const data = await API.get("/myloop/core-myloop/" + shortCode, {
                  headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                  },
                });

                const prodId = data.data.data.myloopId;
                navigate("/register?prodId=" + prodId);
              }
            }
          }
        },
        {
          // highlightScanRegion: true,
        }
      );
    qrScanner.current.start();

    return () => {
      qrScanner.current.stop();
    };
  }, []);

  useEffect(() => {
    if (scanned === true) qrScanner.current.stop();
  }, [scanned]);

  if (scanned) {
    return <iframe title="scanned" className="iframe" src={url} width="100%" />;
  }

  return (
    <>
      <div className="header">
        <Button
          onClick={() => {
            localStorage.setItem("token", "");
            window.location.href = "/login";
          }}
        >
          Logout
        </Button>
      </div>

      <div className="App">
        <video className="qr" ref={qrRef}></video>

        <div className="qr-overlay">
          <div className="box"></div>
        </div>
        <button
          onClick={() => {
            qrScanner.current.start();
          }}
        >
          Start
        </button>
        <button
          onClick={() => {
            qrScanner.current.stop();
          }}
        >
          Stop
        </button>
      </div>
    </>
  );
}
