import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../api";
import { notification } from "antd";

export default function Login() {
  const [step, setStep] = useState(0);
  const [mobile, setMobile] = useState("");

  const navigate = useNavigate();
  const onFinish = async (values) => {
    setMobile(values.mobile);
    try {
      await API.post("/myloop/verification/", {
        phone_number: values.mobile,
        country_code: "+91",
      });
      setStep(1);
      notification["success"]({
        message: "OTP Sent",
        description: "OTP was sent to the mobile number.",
      });
    } catch (error) {
      notification["error"]({
        message: "Some error occurred",
        description: "There was some sending the otp. Please try again.",
      });
    }
  };

  const handleVerifyOTP = async (values) => {
    const data = await API.post("/myloop/verification-check/", {
      phone_number: mobile,
      country_code: "+91",
      otp: values.otp,
    });

    const token = data?.data?.data?.token;
    localStorage.setItem("token", token);
    navigate("/");
  };

  return (
    <div className="flex">
      {step === 0 && (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[{ required: true, message: "Please fill your mobile!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Send OTP
            </Button>
          </Form.Item>
        </Form>
      )}

      {step === 1 && (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={handleVerifyOTP}
          autoComplete="off"
        >
          <Form.Item
            label="OTP"
            name="otp"
            rules={[{ required: true, message: "Please fill your OTP!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Verify OTP
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
