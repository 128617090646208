import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Button, Select, DatePicker, Upload } from "antd";
import { API } from "../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notification, Space } from "antd";
import ImageUpload from "../components/imageUpload";

const { Option } = Select;

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 70,
      }}
      defaultValue={{ value: "91", label: "+91" }}
    >
      <Option selected={true} value="91">
        +91
      </Option>
    </Select>
  </Form.Item>
);

const validateMessages = {
  required: "${label} is required!",
};

const ProductActivationForm = () => {
  const [searchParams] = useSearchParams();
  const prodId = searchParams.get("prodId");
  const navigate = useNavigate();
  const [categories, setCategofies] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token || token === "") {
      navigate("/login");
    }

    if (prodId === null) {
      navigate("/");
    }
  }, [prodId, navigate, token]);

  const getCategories = async () => {
    try {
      const data = await API.get("/myloop/categories/", {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      setCategofies(data.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getCategories();
  }, []);

  const openNotificationWithIcon = (message) => {
    notification["error"]({
      message: "Some error occurred",
      description:
        message ||
        "There was some error in registering your product. Please try again.",
    });
  };

  const onFinish = async (values) => {
    try {
      await API("/myloop/activate/?pid=" + prodId, {
        method: "POST",
        data: {
          productCategory: values.productCategory,
          productName: values.productName,
          brandName: values.brandName,
          modelNumber: values.modelNumber,
          phoneNumberCustomer: "+91" + values.phoneNumberCustomer,
          first_name: values.first_name,
          last_name: values.last_name,
          city: values.city,
          pin_code: values.pin_code,
          dateOfPurchase: values.dateOfPurchase.format("YYYY-MM-DD"),
          code: values.code,
          serialNumber: values.serialNumber,
          serviceTag: values.serviceTag,
          paymentReference: values.paymentReference,
          productImage: null,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });

      navigate("/thanks");
    } catch (error) {
      const message = error?.response?.data?.data?.[0];

      openNotificationWithIcon(
        message || "There was some error, please contact admin for help."
      );
    }
  };

  return (
    <div className="form">
      <br />
      <h2> Register details of product </h2>
      <hr />
      <Form
        // layout="horizontal"
        validateMessages={validateMessages}
        onFinish={onFinish}
      >
        <Form.Item
          name="productImage"
          label="Upload Images of Product"
          valuePropName="fileList"
        >
          <ImageUpload />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="productCategory"
          label="Product Category"
        >
          <Select>
            {categories.map((item, idx) => {
              return (
                <Select.Option key={idx} value={item.name}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="brandName"
          label="Brand Name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="modelNumber"
          label="Model Number"
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="serialNumber"
          label="Serial Number"
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="serviceTag"
          label="Service Tag"
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="first_name"
          label="First Name (of Customer)"
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="last_name"
          label="Last Name (of Customer)"
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="city"
          label="City of activation"
        >
          <Select>
            {["Delhi", "Pune"].map((item, idx) => {
              return (
                <Select.Option key={idx} value={item}>
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter a valid pin code",
              pattern: new RegExp(/^[0-9]+$/),
            },
          ]}
          name="pin_code"
          maxLength={6}
          label="Area Pincode"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phoneNumberCustomer"
          label="Phone Number"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="dateOfPurchase"
          label="Date of Purchase"
        >
          <DatePicker />
        </Form.Item>

        <Form.Item rules={[{ required: true }]} name="code" label="Code">
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="paymentReference"
          label="Payment Reference"
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default () => <ProductActivationForm />;
