import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useAuth() {
  let navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      !localStorage.getItem("token") === ""
    ) {
      navigate("/login", { replace: true });
    }
  }, []);

  return null;
}
